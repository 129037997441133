import { StorageKeys } from "constants/storageKeys";
import { type IStoreSliceState } from "store/types/store";
import { getLocalStorageItem } from "utils/localStorage";

export const getInitialState = (): IStoreSliceState => ({
  isLoading: false,
  store: null,
  storeFetched: false,
  cartItems: getLocalStorageItem(StorageKeys.Cart, []) ?? [],
});
