import { Button, styled } from "@mui/material";

const CategoryButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: "20px",
  padding: "8px 16px",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

export default CategoryButton;
