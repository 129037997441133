import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { type IStoreCustomer } from "dataLayer/types/base";
import { type IAction } from "store/store";
import { type ICoreSliceState } from "store/types/core";

const createReducer = <T extends SliceCaseReducers<ICoreSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setIsFirebaseInitializationInProgress(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitializationInProgress: action.payload,
    };
  },
  setIsFirebaseInitialized(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitialized: action.payload,
    };
  },
  setUser(state, action: IAction<IStoreCustomer | null>) {
    state.user = action.payload;
  },
});

export default reducers;
