import { Button, DialogContent, Grid, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import SteamLogo from "assets/images/auth/steam.png";
import { useCallback } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { useLocation } from "react-router-dom";
import { setLocalStorageItem } from "utils/localStorage";
import { StorageKeys } from "constants/storageKeys";

const FormBody = () => {
  const [t] = useTranslation();
  const store = useAppSelector(storeSelector.store);
  const { pathname } = useLocation();

  const savePathBeforeRedirect = useCallback(() => {
    setLocalStorageItem(StorageKeys.PathBeforeRedirect, pathname);
  }, [pathname]);

  const onSteamClick = useCallback(() => {
    if (store) {
      const host = window.location.host;
      const storeId = store.id;
      savePathBeforeRedirect();
      window.location.href = `/auth/steam?storeId=${storeId}&host=${host}`;
    }
  }, [store, savePathBeforeRedirect]);

  return (
    <>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {t(Translations.MODAL_SIGN_IN_SUBTITLE)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={onSteamClick} variant="outlined" color="primary">
              <img src={SteamLogo} onClick={onSteamClick} width={"100%"} />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default FormBody;
