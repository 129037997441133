import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { StorageKeys } from "constants/storageKeys";
import { type IAction } from "store/store";
import { type IStoreCartItem, type ClientStoreDocument, type IStoreSliceState } from "store/types/store";
import { setLocalStorageItem } from "utils/localStorage";

const createReducer = <T extends SliceCaseReducers<IStoreSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setStoreLoading(state, action: IAction<boolean>) {
    state.isLoading = action.payload;
  },
  setStoreFetched(state, action: IAction<boolean>) {
    state.storeFetched = action.payload;
  },
  setStore(state, action: IAction<ClientStoreDocument | null>) {
    state.store = action.payload;
  },
  addCartItem(state, action: IAction<IStoreCartItem>) {
    state.cartItems.push(action.payload);
    setLocalStorageItem(StorageKeys.Cart, state.cartItems);
  },
  removeCartItem(state, action: IAction<string>) {
    state.cartItems = state.cartItems.filter((item) => item.productId !== action.payload);
    setLocalStorageItem(StorageKeys.Cart, state.cartItems);
  },
  updateCartItemQuantity(state, action: IAction<{ productId: string; quantity: number }>) {
    const { productId, quantity } = action.payload;
    const item = state.cartItems.find((item) => item.productId === productId);
    if (item) {
      item.quantity = quantity;
    }
    setLocalStorageItem(StorageKeys.Cart, state.cartItems);
  },
});

export default reducers;
