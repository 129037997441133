import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { useCallback, useContext, useEffect, useMemo } from "react";
import StoreContext from "../../../context/StoreContext";
import { defaultCategory } from "constants/store";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import CategoryButton from "./CategoryButton";

const StoreNavigation = () => {
  const [t] = useTranslation();
  const store = useAppSelector(storeSelector.store);
  const { selectedCategory, setSelectedCategory } = useContext(StoreContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");

  const buttonConfigs = useMemo(() => {
    const configs = [
      {
        id: "all",
        title: t(Translations.PAGE_STORE_CATEGORY_ALL),
      },
    ];

    if (store?.entities.categories) {
      store.entities.categories.forEach((category) => {
        configs.push({
          id: category.id,
          title: category.title,
        });
      });
    }

    return configs;
  }, [store, t]);

  useEffect(() => {
    setSearchParams({ category: selectedCategory });
  }, [selectedCategory, setSearchParams]);

  const onCategoryClick = useCallback(
    (category: string) => {
      selectedCategory === category ? setSelectedCategory(defaultCategory) : setSelectedCategory(category);
    },
    [selectedCategory, setSelectedCategory],
  );

  return (
    <nav>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {buttonConfigs.map((buttonConfig) => (
          <CategoryButton
            key={buttonConfig.id}
            sx={{ margin: "0 8px" }}
            variant={categoryParam === buttonConfig.id ? "contained" : "outlined"}
            onClick={() => {
              onCategoryClick(buttonConfig.id);
            }}
          >
            {buttonConfig.title}
          </CategoryButton>
        ))}
      </div>
    </nav>
  );
};

export default StoreNavigation;
