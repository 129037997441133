import { Typography, CircularProgress } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

import StorefrontIcon from "@mui/icons-material/Storefront";
import { ImageIcon } from "components/common/ImageIcon";

interface StoreInfoProps {}
const StoreInfo = (props: StoreInfoProps) => {
  const store = useAppSelector(storeSelector.store);
  const storeFetched = useAppSelector(storeSelector.storeFetched);

  const navigate = useNavigate();
  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return storeFetched ? (
    <React.Fragment>
      {store?.settings.style?.logoURL ? (
        <ImageIcon iconURL={store?.settings.style?.logoURL} />
      ) : (
        <StorefrontIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
      )}

      <Typography
        variant="h6"
        noWrap
        component="div"
        onClick={onHomeClick}
        sx={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        {store?.title ?? ""}
      </Typography>
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
};

export default StoreInfo;
