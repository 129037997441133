import { Badge, Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

const CartButton = () => {
  const [t] = useTranslation();
  const cartItems = useAppSelector(storeSelector.cartItems);
  const navigate = useNavigate();

  const onCartClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <Badge badgeContent={cartItems?.length} color="error" sx={{ mr: "20px" }}>
      <Button startIcon={<ShoppingCartIcon />} onClick={onCartClick}>
        {t(Translations.LAYOUT_ROOT_BUTTON_CART)}
      </Button>
    </Badge>
  );
};

export default CartButton;
