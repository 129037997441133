import { Box, IconButton, Avatar, Menu, MenuItem, Typography, Tooltip } from "@mui/material";
import { type IUniqueItem } from "dataLayer/types";
import { type MouseEvent, useState } from "react";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware, coreSelector } from "store/slices/core";

const onSignOut = () => {
  dispatch(coreMiddleware.signOut());
};

interface IOverflowMenuItem extends IUniqueItem {
  clickHandler: () => void;
}

const menuItems: IOverflowMenuItem[] = [
  {
    id: "profile",
    title: "Profile",
    clickHandler: () => {},
  },
  {
    id: "sign-out",
    title: "Sign out",
    clickHandler: onSignOut,
  },
];

const HeadAvatar = () => {
  const user = useAppSelector(coreSelector.user);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (callback?: () => void) => {
    setAnchorElUser(null);
    callback?.();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={user?.displayName} src={user?.avatarUrl ?? ""} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={() => {
          handleCloseUserMenu();
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            onClick={() => {
              handleCloseUserMenu(menuItem.clickHandler);
            }}
          >
            <Typography sx={{ textAlign: "center" }}>{menuItem.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default HeadAvatar;
