import store, { type AppDispatch } from "store/store";
import slice from "./slice";
import FirebaseManager from "dataLayer/FirebaseManager";
import { logError } from "utils/logger";
import { removeLocalStorageItem } from "utils/localStorage";
import { StorageKeys } from "constants/storageKeys";

const { setIsFirebaseInitialized, setIsFirebaseInitializationInProgress, setUser } = slice.actions;

const initialize = () => async (dispatch: AppDispatch) => {
  if (store.getState().core.firebase.isInitializationInProgress) {
    return;
  }
  try {
    setIsFirebaseInitializationInProgress(true);
    FirebaseManager.initializeFirebaseApplication();
    dispatch(setIsFirebaseInitialized(true));
  } catch (error) {
    logError("Firebase Initialization Failed", error);
  } finally {
    dispatch(setIsFirebaseInitializationInProgress(false));
  }
};

const authenticateUser = (storeId: string, customerId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await FirebaseManager.API.Client.getCustomer({ storeId, customerId });
    dispatch(setUser(response.data));
  } catch (error) {
    logError("Sign In Failed", error);
  }
};

const signOut = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setUser(null));
    removeLocalStorageItem(StorageKeys.CustomerId);
    removeLocalStorageItem(StorageKeys.Cart);
  } catch (error) {
    logError("Sign Out Failed", error);
  }
};

export default { initialize, setIsFirebaseInitialized, authenticateUser, signOut };
