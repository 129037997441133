import { AppBar, Container, Toolbar } from "@mui/material";
import StoreInfo from "./Components/StoreInfo";
import HeadNavigation from "./Components/HeadNavigation";
import { useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";
import SignInButton from "./Components/SignInButton";
import SignedInButtons from "./Components/SignedInButtons";

const RootToolbar = () => {
  const user = useAppSelector(coreSelector.user);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper + "E6",
        backdropFilter: "blur(5px)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <StoreInfo />
          <HeadNavigation />
          {user ? <SignedInButtons /> : <SignInButton />}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default RootToolbar;
