import { Grid, IconButton, TextField, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { type DB_StoreProductDocument } from "store/types/store";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface ProductQuantityFieldProps {
  product: DB_StoreProductDocument;
}
const ProductQuantityField = ({ product }: ProductQuantityFieldProps) => {
  const theme = useTheme();
  const cartItems = useAppSelector(storeSelector.cartItems);

  const productInCart = useMemo(
    () => cartItems.find((cartItem) => cartItem.productId === product.id)!,
    [cartItems, product.id],
  );

  const handleIncrement = useCallback(() => {
    dispatch(storeMiddleware.updateCartItemQuantity(product.id, productInCart.quantity + 1));
  }, [productInCart, product.id]);

  const handleDecrement = useCallback(() => {
    if (productInCart.quantity === 1) {
      dispatch(storeMiddleware.removeCartItem(product.id));
      return;
    }
    dispatch(storeMiddleware.updateCartItemQuantity(product.id, productInCart.quantity - 1));
  }, [productInCart, product.id]);

  return (
    <Grid
      container
      sx={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: "20px", px: "5px" }}
      alignItems={"center"}
      justifyContent={"space-evenly"}
    >
      <Grid item xs={4} justifySelf={"start"}>
        <IconButton onClick={handleDecrement}>
          <RemoveIcon />
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <TextField value={productInCart.quantity} />
      </Grid>
      <Grid item xs={4}>
        <IconButton onClick={handleIncrement}>
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ProductQuantityField;
