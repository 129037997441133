import StoreOverview from "components/store/StoreOverview";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const StorePage = () => {
  const [t] = useTranslation();
  const store = useAppSelector(storeSelector.store);
  const storeFetched = useAppSelector(storeSelector.storeFetched);

  return storeFetched ? <>{store ? <StoreOverview /> : <>{t(Translations.PAGE_STORE_UNAVAILABLE_TITLE)}</>}</> : null;
};

export default StorePage;
