import { contextSetterImitator } from "constants/context";
import { defaultCategory } from "constants/store";
import { IUniqueItem } from "dataLayer/types";
import { createContext } from "react";

export interface IStoreContextFields {
  categories: IUniqueItem[];
  selectedCategory: string;
}

export interface IStoreContext extends IStoreContextFields {
  setSelectedCategory: (category: string) => void;
}

const StoreContext = createContext<IStoreContext>({
  categories: [],
  selectedCategory: defaultCategory,
  setSelectedCategory: contextSetterImitator,
});

export default StoreContext;
