import { type DB_StoreProductDocument } from "store/types/store";
import CategoryButton from "../../CategoryButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCallback, useMemo } from "react";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

interface ProductAddButtonProps {
  product: DB_StoreProductDocument;
}

const ProductAddButton = ({ product }: ProductAddButtonProps) => {
  const [t] = useTranslation();
  const cartItems = useAppSelector(storeSelector.cartItems);

  const isProductInCart = useMemo(
    () => !!cartItems.find((addedProduct) => addedProduct.productId === product.id),
    [cartItems, product],
  );

  const handleAddToCart = useCallback(() => {
    if (!isProductInCart) {
      dispatch(storeMiddleware.addCartItem(product.id));
    }
  }, [isProductInCart, product.id]);

  return (
    <CategoryButton startIcon={<ShoppingCartIcon />} variant="outlined" onClick={handleAddToCart}>
      {t(Translations.PAGE_STORE_PRODUCT_BUTTON_ADD)}
    </CategoryButton>
  );
};

export default ProductAddButton;
