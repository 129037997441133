import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { type DB_Record } from "dataLayer/types";
import { ContextMenu } from "./ContextMenu";
import { type IContextActionBinding } from "hooks/useContextMenuAction";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ColumnData<D> {
  dataKey: keyof D;
  label: string;
  width?: string;
}

export interface DataTableActionsProps<D extends DB_Record> {
  headerActionComponent?: React.JSX.Element;
  actionBindingsGenerator?: (rowData: D) => IContextActionBinding[];
}

export interface DataTableProps<D extends DB_Record> {
  data: D[];
  columns: Array<ColumnData<D>>;
  onRowClick?: (rowData: D) => void;
  actions?: DataTableActionsProps<D>;
  emptyState?: React.JSX.Element;
  renderColumns?: (dataKey: ColumnData<D>["dataKey"], rowData: D) => React.ReactNode;
}

const DataTable = <D extends DB_Record>({
  data,
  columns,
  actions,
  emptyState,
  onRowClick,
  renderColumns,
}: DataTableProps<D>) => {
  const [t] = useTranslation();
  return (
    <TableContainer sx={{ maxHeight: 600 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.dataKey as string}
                variant="head"
                align={"left"}
                sx={{
                  backgroundColor: "background.paper",
                }}
                width={column.width}
              >
                {t(column.label)}
              </TableCell>
            ))}
            {actions ? <TableCell align={"center"}>{actions.headerActionComponent}</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData) => (
            <TableRow
              key={rowData.id}
              sx={{
                cursor: !!onRowClick ? "pointer" : "default",
              }}
              hover
            >
              <>
                {columns.map((column) => (
                  <TableCell
                    key={column.dataKey as string}
                    align={"left"}
                    onClick={() => {
                      onRowClick?.(rowData);
                    }}
                  >
                    {renderColumns ? renderColumns(column.dataKey, rowData) : t(rowData[column.dataKey] as string)}
                  </TableCell>
                ))}
                {actions ? (
                  <TableCell align={"center"} width={"5%"}>
                    <Grid item xs={1}>
                      <ContextMenu actionBindings={actions.actionBindingsGenerator?.(rowData) ?? []} />
                    </Grid>
                  </TableCell>
                ) : null}
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!data.length ? emptyState : null}
    </TableContainer>
  );
};

export default DataTable;
