import { type AppDispatch } from "store/store";
import slice from "./slice";
import FirebaseManager from "dataLayer/FirebaseManager";
import { logError } from "utils/logger";

const { setStoreLoading, setStore, setStoreFetched } = slice.actions;

const initialize = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreLoading(true));
    const response = await FirebaseManager.API.Client.getStoreByUrl({
      url: window.location.host,
    });

    dispatch(setStore(response.data.store));
  } catch (error) {
    logError("Store Initialization Failed", error);
  } finally {
    dispatch(setStoreFetched(true));
    dispatch(setStoreLoading(false));
  }
};

const addCartItem = (productId: string) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.addCartItem({ productId, quantity: 1 }));
};

const removeCartItem = (productId: string) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.removeCartItem(productId));
};

const updateCartItemQuantity = (productId: string, quantity: number) => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.updateCartItemQuantity({ productId, quantity }));
};

export default { initialize, addCartItem, removeCartItem, updateCartItemQuantity };
