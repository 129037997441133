import StoreCategoryContent from "./components/StoreCategoryContent";
import StoreNavigation from "./components/StoreNavigation";

const StoreOverview = () => {
  return (
    <div>
      <StoreNavigation />
      <StoreCategoryContent />
    </div>
  );
};

export default StoreOverview;
