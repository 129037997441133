import { useEffect, useState, type PropsWithChildren } from "react";
import RootContext from "./RootContext";
import { useLocation } from "react-router-dom";

interface RootContextProviderProps extends PropsWithChildren {}

const RootContextProvider = ({ children }: RootContextProviderProps) => {
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split("/");
    paths.shift();
    const firstPath = paths[0];
    setCurrentPage(firstPath);
  }, [setCurrentPage, location]);

  return (
    <RootContext.Provider
      value={{
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export default RootContextProvider;
