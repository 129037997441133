import { Grid, Paper } from "@mui/material";
import StoreContext from "components/context/StoreContext";
import { defaultCategory } from "constants/store";
import { useContext, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import ProductCard from "./ProductCard";

const StoreCategoryContent = () => {
  const store = useAppSelector(storeSelector.store);
  const { selectedCategory, categories } = useContext(StoreContext);

  const products = useMemo(() => {
    if (!store) {
      return [];
    }
    if (selectedCategory === defaultCategory) {
      return store.entities.products;
    } else {
      const targetCategory = store.entities.categories.find((category) => category.id === selectedCategory);
      if (!targetCategory) {
        return [];
      }
      const productIds = targetCategory.items;
      return store.entities.products.filter((product) => productIds.includes(product.id));
    }
  }, [selectedCategory, store]);

  const selectedCategoryTitle = useMemo(() => {
    const targetCategory = categories.find((category) => category.id === selectedCategory);
    return targetCategory ? targetCategory.title : "";
  }, [categories, selectedCategory]);

  return (
    <Paper sx={{ borderRadius: "4px" }}>
      <Grid container sx={{ p: "20px", mt: "20px" }}>
        <h1>{selectedCategoryTitle}</h1>
      </Grid>
      <Grid container spacing={4} sx={{ p: "20px" }}>
        {products.map((product) => (
          <Grid item xs={3} key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default StoreCategoryContent;
