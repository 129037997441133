import { useState } from "react";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import { logError } from "utils/logger";

const useLocalStorageHook = <T>(
  key: string,
  initialValue: T | null = null,
): [T | null, (value: T) => void, () => void] => {
  const [storedValue, setStoredValue] = useState(() => getLocalStorageItem(key, initialValue));

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      setLocalStorageItem(key, valueToStore);
    } catch (error) {
      logError(error);
    }
  };

  const removeValue = () => {
    try {
      removeLocalStorageItem(key);
      setStoredValue(null);
    } catch (error) {
      logError(error);
    }
  };

  return [storedValue, setValue, removeValue];
};

export default useLocalStorageHook;
