import { Box, Container } from "@mui/material";
import { ModalsController } from "components/modals/controller";
import RootToolbar from "components/root/RootToolbar";
import RootContextProvider from "components/root/RootToolbar/context/RootContextProvider";
import { ToastNotificationsController } from "components/toast/ToastNotificationController";
import { StorageKeys } from "constants/storageKeys";
import { type ICustomerId } from "dataLayer/types/getCustomer/getCustomerTypeDefs";
import usePersistentState from "hooks/usePersistentState";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware } from "store/slices/core";
import { storeSelector } from "store/slices/store";
import { decrypt } from "utils/crypto";

const RootLayout = () => {
  const [customerId] = usePersistentState<string>(StorageKeys.CustomerId, null);
  const store = useAppSelector(storeSelector.store);

  useEffect(() => {
    if (customerId && store) {
      const decryptedCustomerId: ICustomerId = decrypt(customerId);
      dispatch(coreMiddleware.authenticateUser(store.id, decryptedCustomerId.customerId));
    }
  }, [customerId, store]);

  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <RootContextProvider>
            <RootToolbar />
          </RootContextProvider>
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
        <ModalsController />
        <ToastNotificationsController />
      </Box>
    </>
  );
};

export default RootLayout;
