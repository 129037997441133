import { useMemo, useState, type PropsWithChildren } from "react";
import StoreContext from "./StoreContext";
import { defaultCategory } from "constants/store";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { type IUniqueItem } from "dataLayer/types";

interface StoreContextProviderProps extends PropsWithChildren {}

const StoreContextProvider = ({ children }: StoreContextProviderProps) => {
  const [t] = useTranslation();
  const store = useAppSelector(storeSelector.store);
  const [selectedCategory, setSelectedCategory] = useState<string>(defaultCategory);

  const categories: IUniqueItem[] = useMemo(() => {
    const configs = [
      {
        id: "all",
        title: t(Translations.PAGE_STORE_CATEGORY_ALL),
      },
    ];

    if (store) {
      store.entities.categories.forEach((category) => {
        configs.push({
          id: category.id,
          title: category.title,
        });
      });
    }

    return configs;
  }, [store, t]);

  return (
    <StoreContext.Provider
      value={{
        categories,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
