import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePaths = () => {
  const [paths, setPaths] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    const currentPath: string[] = location.pathname.split("/").filter((path) => path);
    setPaths(currentPath);
  }, [location]);

  return paths;
};

export default usePaths;
