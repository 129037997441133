import { type HttpsCallable } from "firebase/functions";

export type ApiModule<N extends string, B = any, R = any> = Record<N, HttpsCallable<B, R>>;

export enum Endpoint {
  getStoreByUrl = "getStoreByUrl",
  getCustomer = "getCustomer",
  searchCustomers = "searchCustomers",
}

export enum ApiModules {
  Client = "Client",
}

export type EndpointNameType = Endpoint;

export type TypeAPI = Record<ApiModules, ApiModule<EndpointNameType>>;

export interface AllowedUsers<T> {
  allowedUsers: Array<UserWithPermissions<T>>;
}
export interface UserWithPermissions<T> {
  userId: string;
  permissions: T[];
}

export interface DB_Record {
  id: string;
}

export enum TimeUnit {
  UNKNOWN,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export interface IUniqueItem extends DB_Record {
  title: string;
}
