import { StorageKeys } from "constants/storageKeys";
import { type ICustomerId } from "dataLayer/types/getCustomer/getCustomerTypeDefs";
import useLocalStorageHook from "hooks/useLocalStorageHook";
import useQuery from "hooks/useQuery";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware } from "store/slices/core";
import { storeSelector } from "store/slices/store";
import { decrypt } from "utils/crypto";
import { removeLocalStorageItem, setLocalStorageItem } from "utils/localStorage";

const AuthenticationPage = () => {
  const query = useQuery();
  const encryptedCustomerId: string | null = query.get("customerId");
  const navigate = useNavigate();
  const [currentEncryptedCustomerId] = useLocalStorageHook<string>(StorageKeys.CustomerId);
  const [pathBeforeRedirect] = useLocalStorageHook<string>(StorageKeys.PathBeforeRedirect, "/store");
  const store = useAppSelector(storeSelector.store);

  useEffect(() => {
    if (currentEncryptedCustomerId && currentEncryptedCustomerId !== encryptedCustomerId) {
      dispatch(coreMiddleware.signOut());
    }
    if (encryptedCustomerId && store) {
      setLocalStorageItem(StorageKeys.CustomerId, encryptedCustomerId);
      const decryptedCustomerId: ICustomerId = decrypt(encryptedCustomerId);
      dispatch(coreMiddleware.authenticateUser(store?.id, decryptedCustomerId.customerId));
    }
    navigate(pathBeforeRedirect ?? "/store");
    removeLocalStorageItem(StorageKeys.PathBeforeRedirect);
  }, [currentEncryptedCustomerId, encryptedCustomerId, store, navigate, pathBeforeRedirect]);

  return <>Processing Authentication</>;
};

export default AuthenticationPage;
