import { Box, Button } from "@mui/material";
import { Translations } from "constants/translations";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RootContext from "../context/RootContext";

interface HeadNavigationProps {}

interface IPage {
  path: string;
  title: string;
}

const HeadNavigation = (props: HeadNavigationProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { currentPage } = useContext(RootContext);

  const onPageClick = useCallback(
    (page: IPage) => {
      navigate(`/${page.path}`);
    },
    [navigate],
  );

  const pages: IPage[] = useMemo(
    () => [
      {
        path: "store",
        title: t(Translations.PAGE_STORE_TITLE),
      },
    ],
    [t],
  );
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {pages.map((page) => (
        <Button
          key={page.path}
          onClick={() => {
            onPageClick(page);
          }}
          sx={{
            my: 2,
            color: currentPage === page.path ? "white" : "primary",
            display: "block",
            textDecoration: "underline",
          }}
        >
          {page.title}
        </Button>
      ))}
    </Box>
  );
};

export default HeadNavigation;
