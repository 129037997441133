import { Dialog, DialogTitle } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

import FormBody from "./FormBody";
import FormActions from "./FormActions";
import React from "react";
import { dispatch } from "store/hooks";
import { ModalName } from "store/types/views";
import { viewsMiddleware } from "store/slices/views";

export interface SignInModalProps {}

const SignInModal = (props: SignInModalProps) => {
  const [t] = useTranslation();

  const handleClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.SignInModal));
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>{t(Translations.MODAL_SIGN_IN_TITLE)}</DialogTitle>
      <FormBody />
      <FormActions onClose={handleClose} />
    </Dialog>
  );
};

export default SignInModal;
