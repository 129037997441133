import { useState } from "react";

interface ProductImageProps {
  src: string;
  alt: string;
}

const ProductImage = ({ src, alt }: ProductImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <img
      src={src}
      alt={alt}
      onLoad={() => {
        setLoaded(true);
      }}
      onError={() => {
        setError(true);
      }}
      style={{
        display: loaded && !error ? "block" : "none",
        maxWidth: "75%",
        height: "auto",
        objectFit: "cover",
      }}
    />
  );
};

export default ProductImage;
