import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";

const usePersistentState = <T>(
  key: string,
  defaultValue: T | null = null,
): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const [state, setState] = useState<T | null>(() => getLocalStorageItem(key, defaultValue));

  useEffect(() => {
    setLocalStorageItem(key, state);
  }, [key, state]);

  return [state, setState];
};

export default usePersistentState;
