import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store/store";

const selector = (state: RootState) => state.core;

const user = createSelector([selector], (state) => state.user);
const firebase = createSelector([selector], (state) => state.firebase);

export default {
  user,
  firebase,
};
