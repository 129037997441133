import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import DataTable, { type ColumnData } from "components/common/DataTable";
import ProductImage from "components/store/StoreOverview/components/StoreCategoryContent/ProductCard/ProductImage";
import ProductQuantityField from "components/store/StoreOverview/components/StoreCategoryContent/ProductCard/ProductQuantityField";
import { Translations } from "constants/translations";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { type DB_StoreProductDocument } from "store/types/store";
import InfoIcon from "@mui/icons-material/Info";
import { convertHtmlToString } from "utils/convertHtmlToString";
import usePaths from "hooks/usePaths";

interface ICartProduct extends DB_StoreProductDocument {
  quantity: number;
  totalPrice: string;
}

const columns: Array<ColumnData<ICartProduct>> = [
  { dataKey: "title", label: Translations.PAGE_CART_TABLE_FIELD_TITLE },
  { dataKey: "quantity", label: Translations.PAGE_CART_TABLE_FIELD_QUANTITY },
  { dataKey: "price", label: Translations.PAGE_CART_TABLE_FIELD_PRICE_SINGLE },
  { dataKey: "totalPrice", label: Translations.PAGE_CART_TABLE_FIELD_PRICE_TOTAL },
];

const useRenderColumns = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const paths = usePaths();

  const onProductClick = useCallback(
    (product: DB_StoreProductDocument) => {
      console.log({ paths });
      // navigate(`/store/${}/${product.id}`);
    },
    [navigate, paths],
  );

  const renderColumns = useCallback(
    (dataKey: ColumnData<ICartProduct>["dataKey"], rowData: ICartProduct) => {
      const htmlToStringDescription = convertHtmlToString(rowData.description);
      const shortDescription =
        htmlToStringDescription.length > 100 ? htmlToStringDescription.slice(0, 100) : htmlToStringDescription;
      switch (dataKey) {
        case "price":
        case "totalPrice":
          return `$${rowData[dataKey]}`;
        case "quantity":
          return (
            <Grid container item xs={5}>
              <ProductQuantityField product={rowData} />
            </Grid>
          );
        case "title":
          return (
            <Grid
              container
              alignItems={"center"}
              onClick={() => {
                onProductClick(rowData);
              }}
            >
              <Grid item xs={2}>
                <ProductImage src={rowData.image ?? ""} alt={t(rowData.title)} />
              </Grid>
              <Grid item>
                <h3>{t(rowData.title)}</h3>
              </Grid>
              <Grid item>
                <Tooltip title={shortDescription}>
                  <IconButton>
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          );
        default:
          break;
      }
      return rowData[dataKey];
    },
    [onProductClick, t],
  );

  return { renderColumns };
};

const CartPage = () => {
  const [t] = useTranslation();
  const cartItems = useAppSelector(storeSelector.cartItems);
  const store = useAppSelector(storeSelector.store);
  const products = store!.entities.products;
  const { renderColumns } = useRenderColumns();

  const cartProducts: ICartProduct[] = useMemo(() => {
    return cartItems.map((cartItem) => {
      const product = products.find((productItem) => productItem.id === cartItem.productId)!;
      return {
        ...product,
        totalPrice: (parseFloat(product.price) * cartItem.quantity).toFixed(2),
        quantity: cartItem.quantity,
      };
    });
  }, [cartItems, products]);

  return (
    <Paper>
      <h1>{t(Translations.PAGE_CART_TITLE)}</h1>
      <DataTable data={cartProducts} columns={columns} renderColumns={renderColumns} />
    </Paper>
  );
};

export default CartPage;
