import React, { useEffect } from "react";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import "./utils/i18n";
import RootLayout from "layouts/RootLayout";
import StorePage from "pages/Store";
import NotFoundPage from "pages/NotFound";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware, coreSelector } from "store/slices/core";
import GlobalLoading from "components/GlobalLoading";
import { storeMiddleware, storeSelector } from "store/slices/store";
import StoreLayout from "layouts/StoreLayout";
import StoreContextProvider from "components/context/StoreContextProvider";
import AuthenticationPage from "pages/Authentication";
import CartPage from "pages/Cart";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Navigate to={"/store"} replace />} />
      <Route path="store" element={<StoreLayout />}>
        <Route index element={<StorePage />} />
        <Route path=":category" element={<StorePage />}>
          <Route path=":product" element={<StorePage />} />
        </Route>
      </Route>
      <Route path="authentication" element={<AuthenticationPage />} />
      <Route path="cart" element={<CartPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

const App = () => {
  const firebase = useAppSelector(coreSelector.firebase);
  const storeFetched = useAppSelector(storeSelector.storeFetched);
  const { isInitialized } = firebase;

  const initializationComplete = isInitialized && storeFetched;

  const store = useAppSelector(storeSelector.store);

  useEffect(() => {
    if (!firebase.isInitialized && !firebase.isInitializationInProgress) {
      dispatch(coreMiddleware.initialize());
    }
  }, [firebase]);

  useEffect(() => {
    if (isInitialized) {
      dispatch(storeMiddleware.initialize());
    }
  }, [isInitialized]);

  useEffect(() => {
    document.head.title = store?.title ?? "Loading...";
  }, [store]);

  return (
    <StoreContextProvider>
      {initializationComplete ? <RouterProvider router={router} /> : <GlobalLoading show />}
    </StoreContextProvider>
  );
};

export default App;
