import { contextSetterImitator } from "constants/context";
import { createContext, type Dispatch, type SetStateAction } from "react";

export interface IRootContextFields {
  currentPage: string | null;
}

export interface IRootContext extends IRootContextFields {
  setCurrentPage: Dispatch<SetStateAction<string | null>>;
}

const RootContext = createContext<IRootContext>({
  currentPage: null,
  setCurrentPage: contextSetterImitator,
});

export default RootContext;
