import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store/store";

const selector = (state: RootState) => state.store;

const isLoading = createSelector([selector], (state) => state.isLoading);
const storeFetched = createSelector([selector], (state) => state.storeFetched);
const store = createSelector([selector], (state) => state.store);
const cartItems = createSelector([selector], (state) => state.cartItems);

export default {
  storeFetched,
  isLoading,
  store,
  cartItems,
};
