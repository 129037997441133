import CartButton from "./CartButton";
import HeadAvatar from "./HeadAvatar";

const SignedInButtons = () => {
  return (
    <>
      <CartButton />
      <HeadAvatar />
    </>
  );
};

export default SignedInButtons;
