import { Button } from "@mui/material";
import { type SignInModalProps } from "components/modals/User/SignInModal";
import { Translations } from "constants/translations";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

const SignInButton = () => {
  const [t] = useTranslation();

  const onSignInClick = useCallback(() => {
    const props: SignInModalProps = {};
    dispatch(viewsMiddleware.openModal({ name: ModalName.SignInModal, props }));
  }, []);

  return (
    <Button
      onClick={onSignInClick}
      sx={{
        my: 2,
        display: "block",
        textDecoration: "underline",
      }}
    >
      {t(Translations.LAYOUT_ROOT_BUTTON_SIGN_IN)}
    </Button>
  );
};

export default SignInButton;
