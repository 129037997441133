import { Card, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { type DB_StoreProductDocument } from "store/types/store";
import InfoIcon from "@mui/icons-material/Info";
import { convertHtmlToString } from "utils/convertHtmlToString";
import ProductAddButton from "./ProductAddButton";
import ProductImage from "./ProductImage";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import ProductQuantityField from "./ProductQuantityField";

interface ProductCardProps {
  product: DB_StoreProductDocument;
}

const ProductCard = ({ product }: ProductCardProps) => {
  const theme = useTheme();
  const cartItems = useAppSelector(storeSelector.cartItems);
  const description = convertHtmlToString(product.description);

  const isProductInCart = useMemo(() => {
    return cartItems.some((cartItem) => cartItem.productId === product.id);
  }, [cartItems, product.id]);

  const shortDescription = description.length > 100 ? `${description.slice(0, 100)}...` : description;

  const onProductClick = useCallback(() => {
    console.log("Product clicked:", product);
  }, [product]);

  return (
    <Card>
      <Grid
        container
        sx={{
          padding: "5px",
          pt: "10px",
          borderRadius: "20px",
          backgroundColor: theme.palette.background.default,
        }}
        columnSpacing={0}
      >
        <Grid container justifyContent={"center"} alignContent={"center"} onClick={onProductClick}>
          <ProductImage src={product.image ?? ""} alt={product.title} />
        </Grid>
        <Grid container alignContent={"center"}>
          <Grid item xs={10} justifyContent={"space-between"}>
            <Typography variant="h6">{product.title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={shortDescription}>
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid container item xs={9}>
            {isProductInCart ? <ProductQuantityField product={product} /> : <ProductAddButton product={product} />}
          </Grid>
          <Grid item xs={2} alignContent={"center"}>
            <Typography variant="body1">${product.price}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductCard;
